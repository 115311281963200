import { ProductInterface } from "../Interface/ProductsInterface";

function RtvDiscardBarcode(e:any,setBarcode:any,products:any,setAddedProduct:any,addedProduct:any,filtered:any) {
    setBarcode(e.target.value.trim());
    let filteredNewArray: any = [];
    if (e.target.value.length > 0) {
      // eslint-disable-next-line

      const modifiedBarcode = e.target.value.replace(/^0+/, '');
      // setBarcode(modifiedBarcode);
      // eslint-disable-next-line
  const filteredBarcodeProduct = filtered.map((item: any) => {
    if (
      item?.barcode === modifiedBarcode
    ) {
      const newObj = {
                id: item.id,
            name: item.product.name,
            quantity: 0,
            price: item.printedMrp,
            itemId:item?.product?.id,
            barcode:item?.product?.barcode,
            brandName: item?.product?.brands?.brandName
        // id: item.id,
        // name: item.name,
        // quantity: filtered?.map((item: BillingInterface) => item.barcode === modifiedBarcode || item.barcode.includes(modifiedBarcode)  ? ++item.quantity : 1),
        // barcode: item?.barcode,
        // printedMrp: item.printedMrp,
        // sellingPrice: item.sellingPrice,
        // productCostPrice: item.productCostPrice,
        // expiryDate: item.expiryDate,
        // weight: item?.weight,
      };
      filteredNewArray = [...filteredNewArray, newObj];
      setBarcode("")
      // return newObj;
    }
  })
  // eslint-disable-next-line
  const filteredProduct = products.filter((item: any) => {
    if (
      item?.product?.barcode === modifiedBarcode
    ) {
      const newObj = {
        id: item.id,
            name: item.product.name,
            quantity: 1,
            price: item.printedMrp,
            itemId:item?.product?.id,
            barcode:item?.product?.barcode,
            brandName: item?.product?.brands?.brandName
      };
      filteredNewArray = [...filteredNewArray, newObj];
      setBarcode("")
      // return newObj;
    }else if(
    item?.product?.name.toLowerCase().replace(/ +/g, "") ===
      e.target.value.toLowerCase().replace(/ +/g, "") ||
    item?.product?.name
      .toLowerCase()
      .replace(/ +/g, "")
      ?.includes(e.target.value) === true){
        const newObj = {
          id: item.id,
          name: item.product.name,
          quantity: 0,
          price: item.printedMrp,
          itemId:item?.product?.id,
          barcode:item?.product?.barcode,
          brandName: item?.product?.brands?.brandName
        };
        filteredNewArray = [...filteredNewArray, newObj];
      }
      // return filteredNewArray
    });
    if (!filteredProduct.length) {
      return;
    }
    if(!filteredBarcodeProduct.length){
      return;
    }
      setAddedProduct([...addedProduct,...filteredNewArray]);
    }
}

export default RtvDiscardBarcode