import Box from "@mui/material/Box";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import RtvBarcodeInput from "../../component/inputs/RtvBarcodeInput";
import { RootStateProduct } from "../../Interface/BillerIdInterface";
import { ProductInterface } from "../../Interface/ProductsInterface";
import RtvDiscardBarcode from "../../utility/RtvDiscardBarcode";
import { createRtvandDiscordStock } from "../discardProduct/action";
import AddedProduct from "../pagesUtils/AddedProduct";

export default function Index() {
  const dispatch: any = useDispatch();
  const [barcode, setBarcode] = React.useState<string>("");
  const { products } = useSelector((state: RootStateProduct) => state.productsReducer);
  const [addedProduct, setAddedProduct] = React.useState<ProductInterface[]>([]);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );
  const filterProduct = addedProduct?.map((a: any) => a.quantity > 0 && a);

  const lastFinalProduct = filterProduct.filter(Boolean);

  const submitRtv = () => {
    setIsDisabled(true);
    dispatch(createRtvandDiscordStock(lastFinalProduct, getStoreId, "false", "Rtv", setAddedProduct, setBarcode, setIsDisabled));
  };

  React.useEffect(() => {
    if (barcode.length < 3) return;

    let filteredNewArray: any = [];
    const normalizedBarcode = barcode.toLowerCase().replace(/ +/g, '');
    const modifiedBarcode = normalizedBarcode.replace(/^0+/, '');


    products.map((item: ProductInterface) => {
      const productName = item?.product?.name.toLowerCase().replace(/ +/g, '');
      // let foundProduct = addedProduct.find(p => p.barcode === modifiedBarcode || p.name.toLowerCase().replace(/ +/g, '') === normalizedBarcode);

      if (item?.product?.barcode === modifiedBarcode) {
        const newObj = {
          id: item.id,
          name: item.product.name,
          quantity: 1,
          price: item.printedMrp,
          itemId: item?.product?.id,
          barcode: item.product?.barcode,
          weight: item?.product?.description,
          printedMrp: item.printedMrp,
          originalQuantity: item?.quantity,
          productCostPrice: item?.productCostPrice,
          brandName: item?.product?.brands?.brandName
        };
        filteredNewArray = [...filteredNewArray, newObj];
        setBarcode("");
        return newObj;

            }else if (productName === normalizedBarcode || productName.includes(normalizedBarcode)) {
              const newObj = {
                id: item.id,
                name: item.product.name,
                quantity: 0,
                price: item.printedMrp,
                itemId: item?.product?.id,
                barcode: item.product?.barcode,
                weight: item?.product?.description,
                printedMrp: item.printedMrp,
                originalQuantity: item?.quantity,
                productCostPrice: item?.productCostPrice,
                brandName: item?.product?.brands?.brandName
              };
              filteredNewArray = [...filteredNewArray, newObj];
              return newObj;
            }
            return null;
          }).filter(item => item !== null);
      
          if (filteredNewArray.length > 0) {
            setAddedProduct([...addedProduct, ...filteredNewArray]);
          }
  }, [products]);

  const arr = addedProduct;
  const ids = arr?.map((o) => o.itemId);

  const filtered = arr?.filter(
    ({ itemId }, index) => !ids?.includes(itemId, index + 1)
  );

  const debug = filtered?.filter(
    (x: any) =>
      x.name.toLowerCase().replace(/ +/g, '').includes(barcode.toLowerCase()) ||
      x["barcode"].includes(barcode.toLowerCase())
  );

  return (
    <Box>
      <Box>
        <Box sx={{ width: "65%" }}>
          <RtvBarcodeInput filtered={filtered} value={barcode} name={"RTV"} onChange={(e: any) => RtvDiscardBarcode(e, setBarcode, products, setAddedProduct, addedProduct, filtered)} />
        </Box>
        <AddedProduct debug={debug} addedProduct={filtered} barcode={barcode} setAddedProduct={setAddedProduct} name={"RTV"} submitForm={submitRtv} disabled={isDisabled} filtered={filtered} setBarcode={setBarcode} />  
      </Box>
    </Box>
  );
}
