import Utils from '../../utility'

const initialState = {
  products: [],
  images: [],
  count: 0,
  order: 'ASC',
  sortField: 'name',
  offset: 1,
  take: 10,
  search: '',
  pin: [],
  loading: true,
  customerName: "",
  customerNumber:"",
  customerCompany:"",
  customerGst:"",
  customerDetails:{},
  invoiceHtml: '',
}

// Action types
const CLEAR_CUSTOMER_DETAILS = 'CLEAR_CUSTOMER_DETAILS';
const CLEAR_INVOICE = 'CLEAR_INVOICE';

export const productsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `${Utils.ActionName.PRODUCTS}`:
      return {
        ...state,
        ...action.payload,
      }
      case CLEAR_CUSTOMER_DETAILS:
      return {
        ...state,
        customerName: '',
        customerNumber: '',
        customerCompany: '',
        customerGst: '',
        customerDetails: {},
      };
      case CLEAR_INVOICE:
        return {
          ...state,
          invoiceHtml: '' 
        };
    default:
      return { ...state }
  }
}

export const clearCustomerDetails = () => ({
  type: CLEAR_CUSTOMER_DETAILS,
});

export const clearInvoiceDetails = () => ({
  type: CLEAR_INVOICE,
});