import React, { useState } from 'react';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, Popover, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import HomeIcon from '@mui/icons-material/Home'; // Example icons
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EventIcon from '@mui/icons-material/Event';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SupportIcon from '@mui/icons-material/Support';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from './logo.jpeg'; // Import your logo image
import { useDispatch } from "react-redux";
import { logoutRefreshToken } from "../../pages/login/action";

const useStyles = makeStyles(() => ({
  sidebar: {
    background: '#1a1a1a', // Sidebar background color
    width: '240px',
    height: '100vh',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    transition: 'width 0.3s', // Smooth transition for width change
  },
  collapsed: {
    width: '60px', // Adjust width when collapsed
    // overflow: 'hidden',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    position: 'relative',
    '& > img': {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
  },
  toggleIcon: {
    position: 'absolute',
    top: '20px',
    right: '-30px', // Make sure it overlaps by placing it outside the sidebar width
    cursor: 'pointer',
    color: 'black',
    backgroundColor: '#d3d3d3',
    borderRadius: '50%',
    padding: '5px',
    zIndex: 2, // Ensure the chevron is above the sidebar for visibility
    transition: 'transform 0.3s', // Smooth transition when toggling
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderRadius: '8px',
    '&:hover': {
      background: '#333', // Hover background color
    },
  },
  activeNavLink: {
    background: '#FFD700', // Gold background for active link
    color: 'black',
  },
  icon: {
    marginRight: '15px',
  },
  supportLink: {
    color: 'white',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    marginBottom: '10px',
    '&:hover': {
      background: '#333',
    },
  },
  logoutButton: {
    color: 'white',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    '&:hover': {
      background: '#333',
    },
    cursor: 'pointer',
  },
  userContainer: {
    bottom: 0, // Push to bottom
    position: "fixed",
    color: 'white',
    // textAlign: 'center',
  },
}));

const Sidebar = ({ collapsed, onToggle }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  // const [collapsed, setCollapsed] = useState(false); // State for collapsing the sidebar
  const classes = useStyles();
  const userName = localStorage.getItem('username'); // Retrieve logged-in user's name from localStorage
  let navigate = useNavigate();
  const dispatch: any = useDispatch();
  
  const logout = () => {
    dispatch(logoutRefreshToken(navigate));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogoutClick = () => {
    setOpen(true);
  };

  return (
    <Box className={`${classes.sidebar} ${collapsed ? classes.collapsed : ''}`}>
      <Box className={classes.logoContainer}>
        <img src={Logo} alt="Logo" loading="lazy" />
        <Box className={classes.toggleIcon} 
        onClick={onToggle}>
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </Box>
      </Box>

      <NavLink
        to="/billing"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <HomeIcon className={classes.icon} />
        {!collapsed && 'Billing'}
      </NavLink>

      <NavLink
        to="/return"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <EventIcon className={classes.icon} />
        {!collapsed && 'Return'}
      </NavLink>

      <NavLink
        to="/dashboard"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <DashboardIcon className={classes.icon} />
        {!collapsed && 'Dashboard'}
      </NavLink>

      <NavLink
        to="/credit"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <CreditCardIcon className={classes.icon} />
        {!collapsed && 'Credit'}
      </NavLink>

      <NavLink
        to="/online-order"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <ShoppingCartIcon className={classes.icon} />
        {!collapsed && 'Online Orders'}
      </NavLink>

      <NavLink
        to="/rtv"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <FileCopyIcon className={classes.icon} />
        {!collapsed && 'RTV'}
      </NavLink>

      <NavLink
        to="/discard"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <FileCopyIcon className={classes.icon} />
        {!collapsed && 'Discard'}
      </NavLink>

      <NavLink
        to="/cashout"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <CreditCardIcon className={classes.icon} />
        {!collapsed && 'Cashout'}
      </NavLink>

      <NavLink
        to="/audit"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <FileCopyIcon className={classes.icon} />
        {!collapsed && 'Audit'}
      </NavLink>

      <PopupState variant="popover" popupId="support-popup">
        {(popupState) => (
          <>
            <Box
              className={classes.supportLink}
              {...bindTrigger(popupState)}
            >
              <SupportIcon className={classes.icon} />
              {!collapsed && 'Support'}
            </Box>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>
                <b>24 Hour Helpline Number</b>
                <br /> +91 83687 69360
              </Typography>
              <hr />
              <Typography
                sx={{ p: 2 }}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://support.thenewshop.in/support/login')}
              >
                <b>Report other issues</b>
              </Typography>
            </Popover>
          </>
        )}
      </PopupState>

      <Box
        className={classes.logoutButton}
        onClick={handleLogoutClick}
      >
        <ExitToAppIcon className={classes.icon} />
        {!collapsed && 'Logout'}
      </Box>

      <Box className={classes.userContainer}>
        <Typography variant="body2">User:</Typography>
        <Typography variant="body1"><b>{userName}</b></Typography>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={logout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sidebar;
