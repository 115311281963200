import moment from "moment";
import Utils from '../../utility';
  export const getAllOrdersTimeInterval = (callBack) => {
    const currentDate = new Date();
    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    
    const formattedFromDate = oneWeekAgo.toISOString();
    const formattedToDate = currentDate.toISOString();
    const getStoreId = localStorage.getItem('storeId')


    return (dispatch, getState) => {
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: { loading: true },
      });
      const {
        order,
        offset,
        take,
        search,
        ordersList,
      } = getState().ordersReducer;
      Utils.api.getApiCall(
        Utils.endPoints.orders,
        `?order=${order}&offset=${offset}&take=${take}&search=${search}&orderType=tns-app&store=${getStoreId}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`,
        (respData:any) => {
          dispatch({
            type: Utils.ActionName.ORDERS,
            payload: {
              ordersList: respData.data?.order,
              // count: respData?.data?.count,
            },
          });
          callBack(respData?.data?.order[0]?.id, ordersList[0]?.id);
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: false },
          });
        },
        (error) => {
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: false },
          });
          let { data } = error;
          Utils.showAlert(
            2,
            `${Utils.endPoints.orders} GET Method error ${
              JSON.stringify(data?.message) || data?.error
            }`
          );
        }
      );
    };
  };

export const getAllOrders = (storeID: any, testFromDate?: any, testToDate?: any) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    const { order, offset, take, search, fromDate, toDate } = getState().ordersReducer;

    // Determine the date values to use
    const from = testFromDate ? moment(testFromDate).format("YYYY-MM-DD") : fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
    const to = testToDate ? moment(testToDate).format("YYYY-MM-DD") : toDate ? moment(toDate).format("YYYY-MM-DD") : null;

    Utils.api.getApiCall(
      Utils.endPoints.orders,
      `?order=${order}&take=${take}&offset=${offset}&search=${search}&orderType=tns-app${
        from ? "&fromDate=" + from + " 00:00:00" : ""
      }${
        to ? "&toDate=" + to + " 23:59:59" : ""
      }`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.ORDERS,
          payload: {
            ordersList: respData.data?.order,
            count: respData?.data?.count,
            loading: false,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(2, data?.message);
      },
    );
  };
};

export const creatOrder = (
  values: any,
  products: any,
  totalAmount: any,
  open?: string,
  storeId?: any,
) => {
  return () => {
    let dataToSend = {}
    dataToSend = {
      product: products.map((item: any) => {
        return {
          product: item.id,
          quantity: item.quantity,
          printedMrp: item.printedMrp,
          sellingPrice: item.sellingPrice,
          productCostPrice: item.productCostPrice,
          expiryDate: item.expiryDate,
        }
      }),
      paymentMethod: values.payType ? values.payType : open,
      customerPhoneNumber: values.phoneNumber,
      store: parseInt(storeId),
      age: values.ageGroup ? values.ageGroup : '',
      notes: '',
      gender: values.gender ? values.gender : '',
      orderAmountTotal: `${totalAmount}`,
    }
    Utils.api.postApiCall(
      Utils.endPoints.orderCreate,
      dataToSend,
      () => {
        Utils.showAlert(2, 'Success')
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const zomatoUpdate = (
  orderId: any,
  storeID: any,
  deliveryMode?:string,
   fromDate?: any,
    toDate?: any,
) => {
  return (dispatch:any) => {
    let dataToSend = {}
    dataToSend={
      deliveryMode:deliveryMode
    }
    Utils.api.postApiCall(
      Utils.endPoints.orders + `/delivery/${orderId}`,
      dataToSend,
      () => {
        Utils.showAlert(1, `${deliveryMode==="store"?"Store Driver Assigned":'Zomato Driver Assigned'}`)
        dispatch(getAllOrders(storeID))
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const getRunnerList = () => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      'users?order=ASC&offset=1&take=10&sortField=userType&user=R',
      '',
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.ORDERS,
          payload: {
            runnerList: respData.data?.users,
          },
        })
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const addNewItemInOrder = (
  values: any,
  setOpen?: any,
  storeID?: any,
) => {
  return (dispatch: any) => {
    const { orderId, products, orderAmountTotal } = values
    let dataToSend = {}
    dataToSend = {
      product: products.map((item: any) => {
        return {
          product: item.id,
          quantity: item.quantity,
          sellingPrice: item.sellingPrice,
        }
      }),
      orderAmountTotal: orderAmountTotal,
    }
    Utils.api.putApiCall(
      Utils.endPoints.orders + '/' + orderId,
      dataToSend,
      () => {
        Utils.showAlert(1, 'Success')
        setOpen(null)
        dispatch(getAllOrders(storeID))
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const asignRunner = (id: any, runner: any) => {
  return () => {
    let dataToSend = {
      runner,
    }
    Utils.api.putApiCall(
      Utils.endPoints.runnerAsign + id,
      dataToSend,
      () => {},
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const updateOrderStatus = (id: any, status: any, storeID: any,fromDate:any,toDate:any) => {
  return (dispatch: any) => {
    let dataToSend = {}
    Utils.api.putApiCall(
      Utils.endPoints.orderStatusUpdate + +id + `?status=${status}`,
      dataToSend,
      () => {
        Utils.showAlert(1, 'Success')
        dispatch(getAllOrders(storeID))
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

//updateSingleOrderStatus
export const updateSingleOrderStatus = (id: any, status: any, storeID: any,fromDate?: any,toDate?: any) => {
  return (dispatch:any) => {
    let dataToSend = {}
    Utils.api.putApiCall(
      Utils.endPoints.orderStatusUpdate + `${storeID}/${id}?status=${status}`,
      dataToSend,
      () => {
        Utils.showAlert(1, 'Order successfully delivered')
        dispatch(getAllOrders(storeID))
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}
//orders by id
export const getOrdersById = (values: any, fromDate: any, toDate: any) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const { orderId } = values
    const { offset } = getState().productsReducer
    Utils.api.getApiCall(
      Utils.endPoints.getOrderById,
      `${orderId}?offset=${offset}&take=${10}&fromDate=${fromDate}&toDate=${toDate}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.ORDERS,
          payload: {
            ordersList: respData.data?.order,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}
//delete product
export const deleteSingleProduct = (id: number, storeId: any,fromDate:any,toDate:any) => {
  return (dispatch: any) => {
    Utils.api.deleteApiCall(
      Utils.endPoints.deleteOrderProduct + `${id}`,
      '',
      () => {
        Utils.showAlert(1, 'Success')
        dispatch(getAllOrders(storeId))
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const cancelOrder = (id) => {
  const getStoreId = localStorage.getItem("storeId");
  return (dispatch) => {
    Utils.api.postApiCall(
      Utils.endPoints.cancelOrder + `${id}`,
      {},
      (respData) => {
        Utils.showAlert(1, "Success");
        dispatch(getAllOrders(getStoreId))
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(2, data?.message)
      }
    );
  };
};
export const acceptOrder = (id,status) => {
    const getStoreId = localStorage.getItem("storeId");
  return (dispatch:any) => {
    let dataToSend = {}
    Utils.api.putApiCall(
      Utils.endPoints.acceptOrder + `${getStoreId}/${id}?status=${status}`,
  dataToSend,
      () => {
        Utils.showAlert(1, 'Success')
        dispatch(getAllOrders(getStoreId))
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
};

