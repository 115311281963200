import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import SingleCard from "./singleOrder";
import TablePagination from "@mui/material/TablePagination";
import ADDProduct from "./addProduct";
import Modal from "../../component/modal";
import Utils from "../../utility";
import { useDispatch } from "react-redux";
import { getAllOrders, getRunnerList } from "./action";
import { useSelector } from "react-redux";
import LoadingComponent from "../../utility/LoadingComponent";
import { Formik, Form } from 'formik'
import DatePicker from './DatePicker'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
// import navy_alarm from './navy_alarm.mp3'

export default function Index() {
  const dispatch: any = useDispatch();
  const [open, setOpen] = React.useState<boolean>(false);
  const getStoreIdLocal = localStorage.getItem("storeId");
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );
  // const [song] = React.useState<any>(new Audio(navy_alarm))
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const formattedFromDate = oneWeekAgo.toISOString() + " 00:00:00";
  const formattedToDate = currentDate.toISOString() + " 23:59:59";

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,newPage: any) => {
    dispatch({
      type: Utils.ActionName.ORDERS,
      payload: { offset: newPage + 1  },
    });
    dispatch(getAllOrders(getStoreId));
  };

  const handleChangeRowsPerPage = (event: any) => {
    dispatch({
      type: Utils.ActionName.ORDERS,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getAllOrders(getStoreId));
  };

  React.useEffect(() => {
    dispatch(getAllOrders(getStoreIdLocal));
    dispatch(getRunnerList());
    return () => {
      dispatch({
        type: Utils.ActionName.ORDERS,
        payload: {
          ordersList: [],
        },
      });
    };
    // eslint-disable-next-line
  }, [dispatch]);

  const { ordersList, filterdOrder, offset,take,loading, 
       fromDate,
    toDate,orderStatusData, tokenData
   } = useSelector(
    (state: any) => state.ordersReducer
  );

  // React.useEffect(() => {
  //   // song.play()
  //   const interval = setInterval(
  //     () =>
  //       dispatch(
  //         getAllOrdersTimeInterval((res, preCount) => {
  //           if (res !== preCount) {
  //             song.play()
  //             setTimeout(() => {
  //               song.pause()
  //             }, 5000)
  //             dispatch({
  //               type: Utils.ActionName.ONLINE_ORDERS,
  //               payload: {
  //                 // orderList: respData.data?.order,
  //                 count: res,
  //               },
  //             })
  //           }
  //         }),
  //       ),
  //     180 * 1000,
  //   )

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval)
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [])

  React.useEffect(() => {
    if (ordersList.length) {
      const newData = ordersList.map((item: any) => {
        return {
          id: item.id,
          orderId: item?.id,
          orderDate: item?.orderDate,
          customerName: item.customer?.name,
          orderAmountNet: item.orderAmountNet,
          orderAmountTotal: item.orderAmountTotal,
          phoneNumber: item?.customer?.phoneNumber,
          addressLine1: item?.fromAddress?.addressLine1,
          addressLine2: item?.fromAddress?.addressLine2,
          lat: item?.fromAddress?.lat,
          long: item?.fromAddress?.long,
          totalQuantity: item.totalQuantity,
          orderStatus: item?.orderStatus,
          test:item?.orderStatus,
          runnerId: item?.runner?.id,
          stateInvoiceNo: item?.stateInvoiceNo,
          products: item.actualBillingSent.map((prod: any) => {
            return {
              quantity: prod?.quantity,
              sellingPrice: prod?.sellingPrice,
              name: prod?.product?.name,
              id: prod?.product?.id,
              barcode: prod?.product?.barcode,
              status: "",
              billingId:prod?.id
            };
          }),
          zomato: item?.zomato,
          token:item?.token,
          deliveryMode:item?.deliveryMode,
          storeName: item?.store?.storename
        };
      });
      dispatch({
        type: Utils.ActionName.ORDERS,
        payload: {
          filterdOrder: newData,
          orderStatusData: newData.orderStatus,
          tokenData: newData.token,
        },
      });
    }
  }, [ordersList,dispatch]);

  const initialValues = {
    storeId: null,
    toDate,
    fromDate,
  }
  // console.log(orderStatusData,"data")

  return (
    <React.Fragment>
      {loading===true ? <LoadingComponent />:(
        <React.Fragment>
              <DatePicker />
        <Box>
  {filterdOrder?.length ? (
    filterdOrder
      .map((item: any, i: number) => (
        <Paper sx={{ my: 2 }} key={i}>
          <SingleCard data={item} open={open} setOpen={setOpen} index={i} />
        </Paper>
      ))
  ) : (
    <h1 style={{ textAlign: "center" }}>No data to show</h1>
  )}
      </Box>
{/* {filterdOrder?.length && orderStatusData !== undefined &&
  orderStatusData !== 'delivered' && 
  orderStatusData !== 'cancelled' && 
  tokenData!=="" ? (
  <TablePagination
    rowsPerPageOptions={[10, 25]}
    component="div"
    count={filterdOrder.length} // Update the count to use the filtered orders
    rowsPerPage={take}
    page={offset - 1}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    nextIconButtonProps={{ disabled: filterdOrder.length < 10 }} // Adjust based on the filtered orders
  />
) : (
  <h1 style={{ textAlign: "center" }}>No data to show</h1>
)} */}

      {filterdOrder?.length ? (
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={ordersList?.length}
        rowsPerPage={take}
        page={offset - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ disabled: ordersList?.length<10 }}
      />
      ):<></>}
      <Modal
        header={"Add Product"}
        children={<ADDProduct orderId={open} setOpen={setOpen} />}
        handleClose={() => {
          setOpen(false);
        }}
        open={open}
        minWidth={1000}
      />
      </React.Fragment>
      )}
          </React.Fragment>
  );
}
